import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1ca54890 = () => interopDefault(import('../pages/audio.vue' /* webpackChunkName: "pages/audio" */))
const _417d8e5e = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _ceb15912 = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _6ff9147a = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _66b2c7ce = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _78257916 = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _61b344bd = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _8665c8ac = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _54459a1d = () => interopDefault(import('../pages/checkoutlogin.vue' /* webpackChunkName: "pages/checkoutlogin" */))
const _58c2b4e3 = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _4dfb846c = () => interopDefault(import('../pages/comm-gov-edu.vue' /* webpackChunkName: "pages/comm-gov-edu" */))
const _50650066 = () => interopDefault(import('../pages/createpassword.vue' /* webpackChunkName: "pages/createpassword" */))
const _22f1ee8a = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _1ba897f8 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _674e806c = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _6cc54483 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProductAlerts.vue' /* webpackChunkName: "" */))
const _f8429318 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _416bb178 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _29811390 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _21d68638 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _47b763b3 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _530036c4 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _1d0bb296 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _526b59bb = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _3a41dfa0 = () => interopDefault(import('../pages/Faq.vue' /* webpackChunkName: "pages/Faq" */))
const _4f561855 = () => interopDefault(import('../pages/holiday-returns-2024.vue' /* webpackChunkName: "pages/holiday-returns-2024" */))
const _296f82b5 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "pages/Home" */))
const _52ab0f42 = () => interopDefault(import('../pages/lighting.vue' /* webpackChunkName: "pages/lighting" */))
const _79a49865 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _22b94957 = () => interopDefault(import('../pages/photography.vue' /* webpackChunkName: "pages/photography" */))
const _5581d6fd = () => interopDefault(import('../pages/returns.vue' /* webpackChunkName: "pages/returns" */))
const _0d14e394 = () => interopDefault(import('../pages/services.vue' /* webpackChunkName: "pages/services" */))
const _1984d22a = () => interopDefault(import('../pages/shipping-policy.vue' /* webpackChunkName: "pages/shipping-policy" */))
const _35d9df3e = () => interopDefault(import('../pages/shop-used-gear.vue' /* webpackChunkName: "pages/shop-used-gear" */))
const _7647281b = () => interopDefault(import('../pages/store.vue' /* webpackChunkName: "pages/store" */))
const _69026ef4 = () => interopDefault(import('../pages/track-order.vue' /* webpackChunkName: "pages/track-order" */))
const _696943ba = () => interopDefault(import('../pages/used-equipment-quote.vue' /* webpackChunkName: "pages/used-equipment-quote" */))
const _60e0e6b5 = () => interopDefault(import('../pages/video.vue' /* webpackChunkName: "pages/video" */))
const _09f6aea1 = () => interopDefault(import('../pages/catalogsearch/result.vue' /* webpackChunkName: "pages/catalogsearch/result" */))
const _d55acf16 = () => interopDefault(import('../pages/order/order_print.vue' /* webpackChunkName: "pages/order/order_print" */))
const _db8b2490 = () => interopDefault(import('../pages/order/orderDetail.vue' /* webpackChunkName: "pages/order/orderDetail" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/audio",
    component: _1ca54890,
    name: "audio___default"
  }, {
    path: "/cart",
    component: _417d8e5e,
    name: "cart___default"
  }, {
    path: "/checkout",
    component: _ceb15912,
    name: "checkout___default",
    children: [{
      path: "billing",
      component: _6ff9147a,
      name: "billing___default"
    }, {
      path: "payment",
      component: _66b2c7ce,
      name: "payment___default"
    }, {
      path: "shipping",
      component: _78257916,
      name: "shipping___default"
    }, {
      path: "thank-you",
      component: _61b344bd,
      name: "thank-you___default"
    }, {
      path: "user-account",
      component: _8665c8ac,
      name: "user-account___default"
    }]
  }, {
    path: "/checkoutlogin",
    component: _54459a1d,
    name: "checkoutlogin___default"
  }, {
    path: "/Cms",
    component: _58c2b4e3,
    name: "Cms___default"
  }, {
    path: "/comm-gov-edu",
    component: _4dfb846c,
    name: "comm-gov-edu___default"
  }, {
    path: "/createpassword",
    component: _50650066,
    name: "createpassword___default"
  }, {
    path: "/customer",
    component: _22f1ee8a,
    meta: {"titleLabel":"My Account"},
    name: "customer___default",
    children: [{
      path: "addresses-details",
      component: _1ba897f8,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___default"
    }, {
      path: "my-newsletter",
      component: _674e806c,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___default"
    }, {
      path: "my-product-alerts",
      component: _6cc54483,
      meta: {"titleLabel":"My Product Alerts"},
      name: "customer-my-product-alerts___default"
    }, {
      path: "my-profile",
      component: _f8429318,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___default"
    }, {
      path: "my-reviews",
      component: _416bb178,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___default"
    }, {
      path: "my-wishlist",
      component: _29811390,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___default"
    }, {
      path: "order-history",
      component: _21d68638,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___default"
    }, {
      path: "/reset-password",
      component: _47b763b3,
      alias: "/customer/account/createPassword",
      name: "reset-password___default"
    }, {
      path: "addresses-details/create",
      component: _530036c4,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___default"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _1d0bb296,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___default"
    }, {
      path: "order-history/:orderId",
      component: _526b59bb,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___default"
    }]
  }, {
    path: "/Faq",
    component: _3a41dfa0,
    name: "Faq___default"
  }, {
    path: "/holiday-returns-2024",
    component: _4f561855,
    name: "holiday-returns-2024___default"
  }, {
    path: "/Home",
    component: _296f82b5,
    name: "Home___default"
  }, {
    path: "/lighting",
    component: _52ab0f42,
    name: "lighting___default"
  }, {
    path: "/Page",
    component: _79a49865,
    name: "Page___default"
  }, {
    path: "/photography",
    component: _22b94957,
    name: "photography___default"
  }, {
    path: "/returns",
    component: _5581d6fd,
    name: "returns___default"
  }, {
    path: "/services",
    component: _0d14e394,
    name: "services___default"
  }, {
    path: "/shipping-policy",
    component: _1984d22a,
    name: "shipping-policy___default"
  }, {
    path: "/shop-used-gear",
    component: _35d9df3e,
    name: "shop-used-gear___default"
  }, {
    path: "/store",
    component: _7647281b,
    name: "store___default"
  }, {
    path: "/track-order",
    component: _69026ef4,
    name: "track-order___default"
  }, {
    path: "/used-equipment-quote",
    component: _696943ba,
    name: "used-equipment-quote___default"
  }, {
    path: "/video",
    component: _60e0e6b5,
    name: "video___default"
  }, {
    path: "/catalogsearch/result",
    component: _09f6aea1,
    name: "catalogsearch-result___default"
  }, {
    path: "/order/order_print",
    component: _d55acf16,
    name: "order-order_print___default"
  }, {
    path: "/order/orderDetail",
    component: _db8b2490,
    name: "order-orderDetail___default"
  }, {
    path: "/",
    component: _296f82b5,
    name: "home___default"
  }, {
    path: "/:slug+",
    component: _79a49865,
    name: "page___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
