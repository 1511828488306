


































































import {
  defineComponent, onMounted, provide, Ref, ref, nextTick, watch,useRoute,
} from '@nuxtjs/composition-api';
import {
  SfAccordion,
  SfButton,
  SfFilter,
  SfHeading,
  SfRadio,
  SfSidebar,
} from '@storefront-ui/vue';

import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import { useUiHelpers } from '~/composables';
import { getFilterConfig, isFilterEnabled } from '~/modules/catalog/category/config/FiltersConfig';
import SelectedFilters from '~/modules/catalog/category/components/filters/FiltersSidebar/SelectedFilters.vue';
import { getProductFilterByCategoryCommand } from '~/modules/catalog/category/components/filters/command/getProductFilterByCategoryCommand';
import { getProductFilterBySearchCommand } from '~/modules/catalog/category/components/filters/command/getProductFilterBySearchCommand';

import type { Aggregation } from '~/modules/GraphQL/types';
import type { SelectedFiltersInterface } from './useFilters';
import { useFilters } from './useFilters';

export interface UseFiltersProviderInterface {
  selectedFilters: Ref<SelectedFiltersInterface>,
  filters: Ref<Aggregation[]>,
}

export default defineComponent({
  name: 'CategoryFilters',
  components: {
    SelectedFilters,
    SkeletonLoader,
    CheckboxType: () => import('~/modules/catalog/category/components/filters/renderer/CheckboxType.vue'),
    SwatchColorType: () => import('~/modules/catalog/category/components/filters/renderer/SwatchColorType.vue'),
    RadioType: () => import('~/modules/catalog/category/components/filters/renderer/RadioType.vue'),
    YesNoType: () => import('~/modules/catalog/category/components/filters/renderer/YesNoType.vue'),
    SfSidebar,
    SfHeading,
    SfAccordion,
    SfFilter,
    SfButton,
    SfRadio,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    catUid: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { changeFilters, clearFilters } = useUiHelpers();
    const removableFilters = ref([]);
    const filters = ref<Aggregation[]>([]);
    const isLoading = ref(true);
    const route = useRoute();

    const {
      selectedFilters, selectFilter, removeFilter, isFilterSelected, getRemovableFilters,
    } = useFilters();

    const updateRemovableFilters = () => {
      removableFilters.value = getRemovableFilters(filters.value, selectedFilters.value);
    };

    const doApplyFilters = () => {
      changeFilters(selectedFilters.value, false);
      updateRemovableFilters();
      if (window?.scroll) {
        window.scroll(0, 0);
      }
      emit('reloadProducts');
      emit('close');
    };

    const doRemoveFilter = ({ id, value }: { id: string, value: string }) => {
      removeFilter(id, value);
      changeFilters(selectedFilters.value, false);
      updateRemovableFilters();
      emit('reloadProducts');
      emit('close');
    };

    const doClearFilters = () => {
      clearFilters(false);
      selectedFilters.value = {};
      updateRemovableFilters();
      emit('reloadProducts');
      emit('close');
    };

    watch(() => props.isVisible, (newValue) => {
      // disable Storefrontt UI's body scroll lock which is launched when :visible prop on SfSidebar changes
      // two next ticks because SfSidebar uses nextTick aswell, and we want to do something after that tick.
      if (newValue) {
        nextTick(() => nextTick(() => clearAllBodyScrollLocks()));
      }
    });


    onMounted(async () => {

      const loadedFilters = await getProductFilterByCategoryCommand.execute({ eq: props.catUid });
      filters.value = loadedFilters.filter((filter) => isFilterEnabled(filter.attribute_code));
      
      updateRemovableFilters();
      isLoading.value = false;
    });

    provide('UseFiltersProvider', { isFilterSelected, selectedFilters, filters });

    return {
      selectFilter,
      doApplyFilters,
      doRemoveFilter,
      doClearFilters,
      getFilterConfig,
      selectedFilters,
      filters,
      isLoading,
      removableFilters,
    };
  },
});
